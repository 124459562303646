import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import React from 'react'
import SettingsIcon from '@material-ui/icons/Settings'
import Tooltip from '@material-ui/core/Tooltip'
import { withAuth } from '@praxis/component-auth'
import { withEnv } from '@praxis/component-runtime-env'
import rfidDeviceTypes from '../deviceTypes/rfidDeviceTypes'
import DeleteRFIDHandheld from '../MenuActionItems/DeleteRFIDHandheld'

function MenuActionItem(props) {
  return (
    <MenuItem
      onClick={() => {
        props.onClose()
        props.onClick()
      }}
    >
      {props.label}
    </MenuItem>
  )
}

class RFIDHandheldActionMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null,
      showDeleteRFIDHandheldDialog: false,
      menuDisabled: true,
      toDoAction: '',
    }
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }
  handleClose = () => {
    this.setState({ anchorEl: null })
  }
  handleMenu = () => {
    this.setState({ menuDisabled: false })
  }
  handleDeleteRFIDHandheldActionClick = () => {
    this.setState({ showDeleteRFIDHandheldDialog: true })
  }
  handleDeleteRFIDHandheldDialogClose = (event) => {
    this.setState({ showDeleteRFIDHandheldDialog: false })
  }

  render() {
    const { anchorEl, menuDisabled } = this.state
    const device_type = 'rfidhandheld'
    function hasAvailableAction(action) {
      return (
        rfidDeviceTypes.hasOwnProperty(device_type) &&
        rfidDeviceTypes[device_type].hasOwnProperty('availableActions') &&
        rfidDeviceTypes[device_type].availableActions.includes(action)
      )
    }
    return (
      <>
        {!menuDisabled && this.props.rowData.mac_address !== undefined ? (
          <Tooltip title="Available Actions">
            <div>
              <IconButton disabled={false} onClick={this.handleClick}>
                <SettingsIcon />
              </IconButton>
            </div>
          </Tooltip>
        ) : (
          <Tooltip title="No Available Actions">
            <div>
              <IconButton disabled={true} onClick={this.handleClick}>
                <SettingsIcon />
              </IconButton>
            </div>
          </Tooltip>
        )}
        <Menu
          style={{ paddingTop: 0, paddingBottom: 0 }}
          disableAutoFocusItem
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {hasAvailableAction('deleteRFIDHandheld') &&
            this.props.env.access.deleteBTScannerGroup.some((r) =>
              this.props.auth.session.userInfo.memberOf.includes(
                r.toUpperCase(),
              ),
            ) &&
            (menuDisabled && this.handleMenu(),
            (
              <MenuActionItem
                onClick={() => this.handleDeleteRFIDHandheldActionClick()}
                label={'Delete RFID Handheld'}
                onClose={() => {
                  this.handleClose()
                }}
              />
            ))}
        </Menu>
        {this.state.showDeleteRFIDHandheldDialog && (
          <DeleteRFIDHandheld
            open={this.state.showDeleteRFIDHandheldDialog}
            onClose={() => {
              this.handleDeleteRFIDHandheldDialogClose()
            }}
            {...this.props}
          />
        )}
      </>
    )
  }
}

export default withAuth(null)(withEnv()(RFIDHandheldActionMenu))
