import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import MUIDataTable from 'mui-datatables'
import React from 'react'
import Spinner from 'nicollet-react/es/components/Spinner/Spinner'
import SvcI10iDevices from '../../../services/svcI10iDevices'
import SvcRFIDScanners from '../../../services/svcRFIDScanners'
import TableOptionsConfig from '../TableOptionsConfig/TableOptionsConfig'
import { Typography } from '@material-ui/core'
import columns from '../TableColumnsConfig/TableColumnConfig'
import { withAnalytics } from '@praxis/component-analytics'
import { withAuth } from '@praxis/component-auth'
import { withEnv } from '@praxis/component-runtime-env'
import { withStyles } from '@material-ui/styles'

class DashboardPlatformsI10iDevices extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.state || {}
    this.state[this.props.platform] = {
      data: [],
      loading: false,
    }
    this.svcI10iDevices = new SvcI10iDevices()
    this.svcRFIDScanners = new SvcRFIDScanners()
    this.apiSucceeded = true
  }
  async componentDidMount() {
    this.setState({
      [this.props.platform]: {
        data: this.state[this.props.platform]?.data || [],
        loading: true,
      },
    })
    await this.getDeviceData()
  }

  getDeviceData = async () => {
    this.svcI10iDevices
      .getDevicesByLocation(
        this.props.env,
        this.props.i10iDeviceTypeList,
        this.props.locationId,
      )
      .then(async (response) => {
        const eventData = {
          customMetrics: {
            metric1: this.props.i10iDeviceTitle,
            metric3: response.status,
          },
          event: {
            type: `apiSuccess${this.props.i10iDeviceTitle}`,
          },
        }
        this.props.trackEvent(eventData)

        // keep below for reference
        // const key = arr.find(key => key in obj)
        // assigns a device type key to each entry
        response.data.forEach((e, index) => {
          response.data[index] = Object.assign(
            {
              device_type: this.props.i10iDeviceTypeList[0],
            },
            e,
          )
        })

        this.setState({
          [this.props.platform]: {
            data: response.data,
            loading: false,
          },
        })

        if (this.props.platform === 'rfidHandheld') {
          await this.svcRFIDScanners
            .getRFIDScanners(this.props.locationId, this.props.env)
            .then((response) => {
              const eventData = {
                customMetrics: {
                  metric1: 'RFID Scanner',
                  metric3: response.status,
                },
                event: {
                  type: `apiSuccessRFIDScanner`,
                },
              }
              this.props.trackEvent(eventData)
              let operationalDataMap = new Map()
              response.data.map((row, index) => {
                operationalDataMap.set(row.unique_id, row)
                return null
              })
              let mergedData = this.state[this.props.platform].data
              const iterator = mergedData.keys()
              for (const key of iterator) {
                let i10iObj = mergedData[key]
                if (operationalDataMap.get(mergedData[key].unique_id)) {
                  i10iObj.device_state = operationalDataMap.get(
                    mergedData[key].unique_id,
                  ).device_state
                  i10iObj.status = operationalDataMap.get(
                    mergedData[key].unique_id,
                  ).status
                  i10iObj.name = operationalDataMap.get(
                    mergedData[key].unique_id,
                  ).name
                  i10iObj.paired_devices = operationalDataMap.get(
                    mergedData[key].unique_id,
                  ).paired_devices
                  i10iObj.updated_on = operationalDataMap.get(
                    mergedData[key].unique_id,
                  ).updated_on
                  i10iObj.mac_address = operationalDataMap.get(
                    mergedData[key].unique_id,
                  ).mac_address
                }
              }
              this.setState({
                [this.props.platform]: {
                  data: mergedData,
                  loading: false,
                },
              })
            })
            .catch((error) => {
              console.log('Error from MICKRA' + error)
            })
        }
      })
      .catch((error) => {
        const eventData = {
          customMetrics: {
            metric1: this.props.i10iDeviceTitle,
            metric3: error.response?.status || 500,
          },
          event: {
            type: `apiError${this.props.i10iDeviceTitle}-i10i`,
          },
        }
        this.props.trackEvent(eventData)
        console.log(
          `Unable to get response for ${this.props.i10iDeviceTitle} data.`,
          error,
        )
        this.setState({
          [this.props.platform]: {
            data: this.state[this.props.platform]?.data || [],
            loading: false,
          },
        })
        this.apiSucceeded = false
      })
  }

  render() {
    const { classes } = this.props
    return (
      <div>
        {!this.state[this.props.platform] ||
        this.state[this.props.platform].loading ? (
          <Spinner />
        ) : this.apiSucceeded ? (
          <MuiThemeProvider theme={this.getMuiTheme()}>
            <MUIDataTable
              data={this.state[this.props.platform]?.data || []}
              title={this.props.i10iDeviceTitle}
              options={TableOptionsConfig(
                this.state[this.props.platform]?.data || [],
                this.props.handleFiltersState,
                this.props.platform,
              )}
              columns={columns(
                null,
                null,
                null,
                this.props.platform,
                null,
                null,
              )}
            />
          </MuiThemeProvider>
        ) : (
          <Typography className={classes.text} variant="h6">
            Note: The selected device data is internal to Target. Please connect
            to a Target network to view.
          </Typography>
        )}
      </div>
    )
  }
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableToolbar: {
          filterPaper: {
            width: 200,
          },
        },
        MuiTableCell: {
          root: {
            paddingTop: 4,
            paddingBottom: 4,
          },
        },
      },
    })
}
const styles = (theme) => ({
  honeyComb: {
    minHeight: `calc(100vh - 75px)`,
    overflow: 'auto',
    backgroundImage:
      `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='24.5' ` +
      `viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23888888' fill-opacity='0.50' ` +
      `fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 ` +
      `11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 ` +
      `0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
  },
  tableRow: {
    cursor: 'cursor',
    width: 'flex',
    paddingLeft: 5,
    paddingTop: 0,
    paddingBottom: 0,
    height: 30,
  },
  metrics: {
    paddingLeft: 14,
  },
  na: {
    textAlign: 'center',
  },
  text: {
    textAlign: 'center',
    margin: 'auto',
    paddingTop: 25,
    fontStyle: 'italic',
  },
})

export default withStyles(styles, { withTheme: true })(
  withAnalytics()(withAuth(null)(withEnv()(DashboardPlatformsI10iDevices))),
)
