import PrintIcon from '@material-ui/icons/Print'
import hipPrinter from '../../../images/hipPrinter.svg'
import sfsPrinter from '../../../images/sfsPrinter.svg'

export const printerDeviceTypes = {
  SFS: {
    device_classification: 'printer',
    platform: 'printer',
    itemIcon: sfsPrinter,
    itemText: 'SFS Printers',
    locationType: ['STR', 'STR-V', 'OFFICE'],
    itemFilter: 'filterSFS',
    availableActions: [
      'editPrinterHostname',
      'printerTools',
      'printerZT410Rebase',
      'printerZT410Test',
      'printerReboot',
      'printerDelete',
      'editPrinterLabel',
      'printerZT410LostStolen',
    ],
    availableHeathItems: [],
  },
  ZT411: {
    device_classification: 'printer',
    platform: 'printer',
    itemIcon: null,
    itemText: null,
    locationType: ['STR', 'STR-V', 'OFFICE'],
    itemFilter: null,
    availableActions: [
      'editPrinterHostname',
      'printerTools',
      'printerZT410Rebase',
      'printerZT410Test',
      'printerReboot',
      'printerDelete',
      'printerZT410LostStolen',
    ],
    availableHeathItems: [],
  },
  HIP: {
    device_classification: 'printer',
    platform: 'printer',
    itemIcon: hipPrinter,
    itemText: 'HIP Printers',
    locationType: ['STR', 'STR-V', 'OFFICE'],
    itemFilter: 'filterHIP',
    availableActions: [
      // 'editPrinterHostname',
      'setPrinterMode',
      'printerTools',
      'printerTOF',
      'printerTest',
      'printerRebase',
      'printerReboot',
      'printerDelete',
      'editPrinterLabel',
      'ringDevice',
      // 'disableNotification',
      'printerLostStolen',
    ],
    availableHeathItems: [],
  },
  Lexmark: {
    device_classification: 'printer',
    platform: 'printer',
    itemIcon: PrintIcon,
    itemText: 'Lexmark Printers',
    locationType: ['STR', 'STR-V', 'OFFICE'],
    itemFilter: 'filterLexmark',
    availableActions: ['launchWebPortal', 'printerDelete'],
    availableHeathItems: [],
  },
  null: {
    device_classification: 'printer',
    platform: 'printer',
    itemIcon: null, //in the printers left nav component null will not render, but we need this for the delete function
    itemText: null,
    locationType: ['STR', 'STR-V', 'OFFICE'],
    itemFilter: null,
    availableActions: [
      'printerDelete',
      'editPrinterLabel',
      'disableNotification',
    ],
    availableHeathItems: [],
  },
}

export default printerDeviceTypes
