export const rfidDeviceTypes = {
  rfidhandheld: {
    device_classification: 'RFID',
    platform: 'rfidHandheld',
    itemIcon: '',
    itemText: 'rfidhandheld',
    itemFilter: 'filterRFIDHandheld',
    availableActions: ['deleteRFIDHandheld'],
    availableHeathItems: [],
  },
}

export default rfidDeviceTypes
