import BTScannersActionsMenu from '../DeviceActionMenu/BTScannersActionMenu'
import RFIDHandheldActionMenu from '../DeviceActionMenu/RFIDHandheldActionMenu'
// import CameraActionsMenu from '../DeviceActionMenu/CameraActionMenu'
// import DeviceHealthCamera from '../DeviceHealth/DeviceHealthCamera'
import DeviceHealthI10i from '../DeviceHealth/DeviceHealthI10i'
import DeviceHealthPrinter from '../DeviceHealth/DeviceHealthPrinter'
import DeviceLastSeenFormat from '../DeviceLastSeenFormat/DeviceLastSeenFormat'
import PrinterActionsMenu from '../DeviceActionMenu/PrinterActionMenu'
import Tooltip from '@material-ui/core/Tooltip'
import l4CamelCase from '../../Devices/I10iExports/l4CamelCase'
import { printerFilterMap } from '../deviceFilterMap/printerFilterMap'
import sort from '../../../utilities/sort'

const toFriendly = (s) => {
  return s.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
    return str.toUpperCase()
  })
}

export function columns(
  filterList,
  customFilters,
  customFiltersPrinters,
  platform,
  access,
  locationId,
  batteryCycleCountThreshold,
) {
  if (platform === 'printer') {
    var arrPrinter = []
    Object.entries(filterList).forEach((item) => {
      if (item[1] === true) {
        arrPrinter.push(printerFilterMap[item[0]])
      }
    })
    customFiltersPrinters.map((item) => arrPrinter.push(item))
    return [
      {
        name: 'is_renamed',
        label: 'Health',
        options: {
          filter: false,
          sort: false,
          viewColumns: false,
          display: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const rowData = new PrinterRowData(tableMeta.rowData)
            return <DeviceHealthPrinter rowData={rowData} />
          },
        },
      },
      {
        name: 'label',
        label: 'Printer Label',
        options: {
          filter: false,
          sortCompare: (order) => {
            return (a, b) => {
              return new sort().label(a, b, order)
            }
          },
          customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
              return value
            } else {
              return 'Not Set'
            }
          },
        },
      },
      {
        name: 'hostname',
        label: 'Hostname',
        options: {
          filter: false,
        },
      },
      {
        name: 'serial_number',
        label: 'Serial Number',
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
              return value
            } else {
              return 'N/A'
            }
          },
        },
      },
      {
        name: 'device_state',
        label: 'State',
        options: {
          filter: true,
        },
      },
      {
        name: 'id',
        label: 'ID',
        options: {
          filter: false,
        },
      },
      {
        name: 'ip_address',
        label: 'IP Address',
        options: {
          filter: false,
        },
      },
      {
        name: 'model_name',
        label: 'Model',
        options: {
          filter: true,
        },
      },
      {
        name: 'firmware',
        label: 'Firmware',
        options: {
          filter: false,
          display: false,
        },
      },
      {
        name: 'department',
        label: 'Device Type',
        options: {
          filterList: arrPrinter,
        },
      },
      {
        name: 'role',
        label: 'Print Mode',
        options: {
          filter: true,
        },
      },
      {
        name: 'updated_on',
        label: 'Updated On',
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const rowData = new PrinterRowData(tableMeta.rowData)
            if (rowData.collection_ts) {
              return (
                <Tooltip title={rowData.collection_ts}>
                  <div>
                    <DeviceLastSeenFormat
                      lastSeenValue={rowData.collection_ts}
                    />
                  </div>
                </Tooltip>
              )
            } else {
              return (
                <Tooltip title={rowData.last_seen}>
                  <div>
                    <DeviceLastSeenFormat lastSeenValue={rowData.last_seen} />
                  </div>
                </Tooltip>
              )
            }
          },
        },
      },
      {
        name: 'mac_address',
        label: 'Mac Address',
        options: {
          filter: false,
          display: false,
        },
      },
      {
        name: 'hostname',
        label: 'Actions',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const rowData = new PrinterRowData(tableMeta.rowData)
            return (
              <PrinterActionsMenu
                rowData={rowData}
                access={access}
                locationId={parseInt(locationId)}
              />
            )
          },
        },
      },
      {
        name: 'location_id',
        label: 'Location',
        options: {
          filter: false,
          sort: false,
          display: false,
        },
      },
      {
        name: 'power_cycle_count',
        label: 'Power Cycle Count',
        options: {
          filter: true,
          sort: false,
          display: false,
          customFilterListOptions: { render: (v) => `Power Health: ${v}` },
          filterOptions: {
            names: ['good', 'replace'],
            logic(count, filterVal) {
              const show =
                (filterVal.indexOf('good') >= 0 &&
                  count < batteryCycleCountThreshold &&
                  count != null) ||
                (filterVal.indexOf('replace') >= 0 &&
                  count >= batteryCycleCountThreshold &&
                  count != null)
              return !show
            },
          },
        },
      },
    ]
  }
  // if (platform === 'camera') {
  //   return [
  //     {
  //       // Health needs to be the first column
  //       // 0
  //       name: 'host_name',
  //       label: 'Health',
  //       options: {
  //         filter: false,
  //         sort: false,
  //         customBodyRender: (value, tableMeta, updateValue) => {
  //           const rowData = new CameraRowData(tableMeta.rowData)
  //           return <DeviceHealthCamera rowData={rowData} />
  //         },
  //       },
  //     },
  //     {
  //       name: 'id',
  //       label: 'ID',
  //       options: {
  //         filter: false,
  //       },
  //     },
  //     {
  //       // 1
  //       name: 'host_name',
  //       label: 'Physical Name',
  //       options: {
  //         filter: false,
  //       },
  //     },
  //     {
  //       // 2
  //       name: 'friendly_name',
  //       label: 'Friendly Name',
  //       options: {
  //         filter: false,
  //       },
  //     },
  //     {
  //       // 3
  //       name: 'ip_address',
  //       label: 'IP Address',
  //       options: {
  //         filter: false,
  //         display: false,
  //       },
  //     },
  //     {
  //       // 4
  //       name: 'model_name',
  //       label: 'Model',
  //       options: {
  //         filter: true,
  //       },
  //     },
  //     {
  //       // 5
  //       name: 'device_type',
  //       label: 'Type',
  //       options: {
  //         display: false,
  //         filter: false,
  //       },
  //     },
  //     {
  //       // 6
  //       name: 'mac_address',
  //       label: 'Mac Address',
  //       options: {
  //         filter: false,
  //       },
  //     },
  //     {
  //       // 7
  //       name: 'updated_on',
  //       label: 'Updated On',
  //       options: {
  //         filter: false,
  //         customBodyRender: (value, tableMeta, updateValue) => {
  //           return value ? (
  //             <Tooltip title={value}>
  //               <div>
  //                 <DeviceLastSeenFormat lastSeenValue={value} />
  //               </div>
  //             </Tooltip>
  //           ) : (
  //             'N/A'
  //           )
  //         },
  //       },
  //     },
  //     {
  //       // 8
  //       name: 'firmware_version',
  //       label: 'Firmware Version',
  //       options: {
  //         display: false,
  //         filter: false,
  //       },
  //     },
  //     {
  //       // 9
  //       name: 'approved_firmware',
  //       label: 'Approved Firmware',
  //       options: {
  //         display: false,
  //         filter: false,
  //       },
  //     },
  //     {
  //       // 10
  //       name: 'device_vlan',
  //       label: 'VLAN',
  //       options: {
  //         display: false,
  //         filter: false,
  //       },
  //     },
  //     {
  //       // 11
  //       name: 'port_number',
  //       label: 'Port Number',
  //       options: {
  //         display: false,
  //         filter: false,
  //       },
  //     },
  //     {
  //       // 12
  //       name: 'ntp_on_dhcp',
  //       label: 'NTP on DHCP',
  //       options: {
  //         display: false,
  //         filter: false,
  //       },
  //     },
  //     {
  //       // 13
  //       name: 'tvs_supported',
  //       label: 'TVS Supported',
  //       options: {
  //         display: false,
  //         filter: false,
  //       },
  //     },
  //     {
  //       // 14
  //       name: 'tvs_type',
  //       label: 'TVS Type',
  //       options: {
  //         display: false,
  //         filter: false,
  //       },
  //     },
  //     {
  //       // 15
  //       name: 'live_vqs_conf_code',
  //       options: {
  //         display: false,
  //         filter: false,
  //       },
  //     },
  //     {
  //       // 16
  //       name: 'low_quality_vqs_conf_code',
  //       options: {
  //         display: false,
  //         filter: false,
  //       },
  //     },
  //     {
  //       //17
  //       name: 'genetec_status',
  //       label: 'Device Status',
  //       options: {
  //         display: false,
  //         filter: false,
  //       },
  //     },
  //     {
  //       // 18
  //       name: 'location_id',
  //       label: 'Location ID',
  //       options: {
  //         display: false,
  //         filter: false,
  //       },
  //     },
  //     {
  //       // 19
  //       name: 'supported_events',
  //       label: 'Supported Events',
  //       options: {
  //         display: false,
  //         filter: false,
  //       },
  //     },
  //     {
  //       // actions needs to be the last column
  //       // 20
  //       name: 'host_name',
  //       label: 'Actions',
  //       options: {
  //         filter: false,
  //         sort: false,
  //         customBodyRender: (value, tableMeta, updateValue) => {
  //           const rowData = new CameraRowData(tableMeta.rowData)
  //           return <CameraActionsMenu rowData={rowData} />
  //         },
  //       },
  //     },
  //   ]
  // }
  if (platform === 'bluetoothscanner') {
    return [
      {
        name: 'label',
        label: 'Device Label',
        options: {
          filter: false,
          sortCompare: (order) => {
            return (a, b) => {
              new sort().label(a, b, order)
            }
          },
          customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
              return value
            } else {
              return 'Not Set'
            }
          },
        },
      },
      {
        name: 'device_state',
        label: 'State',
        options: {
          filter: true,
        },
      },
      {
        name: 'status',
        label: 'Status',
        options: {
          filter: true,
        },
      },
      {
        name: 'id',
        label: 'ID',
        options: {
          filter: false,
        },
      },
      {
        name: 'serial_number',
        label: 'Serial Number',
        options: {
          filter: false,
        },
      },
      {
        name: 'name',
        label: 'Name',
        options: {
          filter: false,
          display: false,
        },
      },
      {
        name: 'mac_address',
        label: 'Mac Address',
        options: {
          filter: false,
          display: false,
        },
      },
      {
        name: 'updated_on',
        label: 'Updated On',
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return value ? (
              <Tooltip title={value}>
                <div>
                  <DeviceLastSeenFormat lastSeenValue={value} />
                </div>
              </Tooltip>
            ) : (
              'N/A'
            )
          },
        },
      },
      {
        name: 'id',
        label: 'Action',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const rowData = new BTScannersRowData(tableMeta.rowData)
            return <BTScannersActionsMenu rowData={rowData} />
          },
        },
      },
    ]
  }
  if (platform === 'rfidHandheld') {
    const rowData = [
      {
        name: 'health',
        label: 'Health',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (tableMeta) => {
            return <DeviceHealthI10i />
          },
        },
      },

      {
        name: 'device_type',
        label: 'Device Type',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            return toFriendly(value)
          },
        },
      },
      {
        name: 'display_name',
        label: 'Display Name',
        options: {
          filter: false,
        },
      },
      {
        name: 'serial_number',
        label: 'Serial Number',
        options: {
          filter: false,
        },
      },
      {
        name: 'status',
        label: 'Status',
        options: {
          filter: true,
        },
      },
      {
        name: 'device_state',
        label: 'State',
        options: {
          filter: true,
        },
      },
      {
        name: 'tcin',
        label: 'TCIN',
        options: {
          filter: true,
        },
      },
      {
        name: 'unique_id',
        label: 'Unique Id',
        options: {
          filter: false,
        },
      },
      {
        name: 'mac_address',
        label: 'Mac Address',
        options: {
          filter: false,
          display: true,
        },
      },
      {
        name: 'actions',
        label: 'Actions',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const rowData = new RFIDScannersRowData(tableMeta.rowData)
            return <RFIDHandheldActionMenu rowData={rowData} />
          },
        },
      },
    ]

    return rowData
  }
  /*
  Is this needed?
  if (platform === 'register') {
    return [
      {
        name: 'id',
        label: 'ID',
        options: {
          filter: false,
        },
      },
      {
        name: 'serial_number',
        label: 'Serial Number',
        options: {
          filter: false,
        },
      },
      {
        name: 'mac_address',
        label: 'Mac Address',
        options: {
          filter: false,
        },
      },
      {
        name: 'gdp_serial_number',
        label: 'GPD',
        options: {
          filter: false,
        },
      },
      {
        name: 'touch_screen_controller',
        label: 'Touchscreen',
      },
      {
        name: 'hand_scanner_serial_number',
        label: 'Hand Scanner',
        options: {
          filter: false,
        },
      },
      {
        name: 'printer_part_number',
        label: 'Printer',
        options: {
          filter: false,
        },
      },
      {
        name: 'flatbed_serial_number',
        label: 'Flatbed',
        options: {
          filter: false,
        },
      },
      {
        name: 'updated_on',
        label: 'Updated On',
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return value ? (
              <Tooltip title={value}>
                <div>
                  <DeviceLastSeenFormat lastSeenValue={value} />
                </div>
              </Tooltip>
            ) : (
              'N/A'
            )
          },
        },
      },
    ]
  }
  */

  /*I10i location dashboard column configuration */
  if (l4CamelCase.includes(platform)) {
    const rowData = [
      {
        name: 'health',
        label: 'Health',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (tableMeta) => {
            return <DeviceHealthI10i />
          },
        },
      },

      {
        name: 'device_type',
        label: 'Device Type',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            return toFriendly(value)
          },
        },
      },
      {
        name: 'display_name',
        label: 'Display Name',
        options: {
          filter: false,
        },
      },
      {
        name: 'hostname',
        label: 'Hostname',
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: 'serial_number',
        label: 'Serial Number',
        options: {
          filter: false,
        },
      },
      {
        name: 'tcin',
        label: 'TCIN',
        options: {
          filter: true,
        },
      },
      {
        name: 'unique_id',
        label: 'Unique Id',
        options: {
          filter: false,
        },
      },
      {
        name: 'tcin_info.model',
        label: 'Model',
        options: {
          filter: true,
        },
      },
      {
        name: 'tcin_info.manufacturer',
        label: 'Manufacturer',
        options: {
          filter: true,
        },
      },
    ]

    return rowData
  }
}

export class I10iRowData {
  health
  device_type
  display_name
  hostname
  serial_number
  tcin
  unique_id
  actions

  constructor(rowData) {
    if (rowData !== undefined) {
      this.health = rowData[0]
      this.device_type = rowData[2]
      this.display_name = rowData[3]
      this.hostname = rowData[4]
      this.serial_number = rowData[5]
      this.tcin = rowData[6]
      this.unique_id = rowData[7]
      this.actions = rowData[19]
    }
  }
}

export class PrinterRowData {
  label
  hostname
  serial_number
  id
  ip_address
  model_name
  firmware
  department
  print_mode
  last_seen
  mac_address
  collection_ts
  value
  location_id

  constructor(rowData) {
    if (rowData !== undefined) {
      this.is_renamed = rowData[0]
      this.label = rowData[1]
      this.hostname = rowData[2]
      this.serial_number = rowData[3]
      this.device_state = rowData[4]
      this.id = rowData[5]
      this.ip_address = rowData[6]
      this.model_name = rowData[7]
      this.firmware = rowData[8]
      this.department = rowData[9]
      this.role = rowData[10]
      this.last_seen = rowData[11]
      this.mac_address = rowData[12]
      this.value = rowData[13]
      this.location_id = rowData[14]
    }
  }
}

// export class CameraRowData {
//   approved_firmware
//   model_name
//   device_type
//   device_vlan
//   firmware_version
//   friendly_name
//   host_name
//   ip_address
//   location_id
//   mac_address
//   ntp_on_dhcp
//   port_number
//   supported_events
//   tvs_supported
//   tvs_type
//   updated_date
//   live_vqs_conf_code
//   low_quality_vqs_conf_code
//   genetec_status

//   constructor(rowData) {
//     if (rowData !== undefined) {
//       this.host_name = rowData[1]
//       this.id = rowData[2]
//       this.friendly_name = rowData[3]
//       this.ip_address = rowData[4]
//       this.model_name = rowData[5]
//       this.device_type = rowData[6]
//       this.mac_address = rowData[7]
//       this.updated_date = rowData[8]
//       this.firmware_version = rowData[9]
//       this.approved_firmware = rowData[10]
//       this.device_vlan = rowData[11]
//       this.port_number = rowData[12]
//       this.ntp_on_dhcp = rowData[13]
//       this.tvs_supported = rowData[14]
//       this.tvs_type = rowData[15]
//       this.live_vqs_conf_code = rowData[16]
//       this.low_quality_vqs_conf_code = rowData[17]
//       this.genetec_status = rowData[18]
//       this.location_id = rowData[19]
//       this.supported_events = rowData[20]
//     }
//   }
// }

export class BTScannersRowData {
  label
  device_state
  status
  id
  serial_number
  name
  mac_address
  updated_on

  constructor(rowData) {
    if (rowData !== undefined) {
      this.label = rowData[0]
      this.device_state = rowData[1]
      this.status = rowData[2]
      this.id = rowData[3]
      this.serial_number = rowData[4]
      this.name = rowData[5]
      this.mac_address = rowData[6]
      this.updated_on = rowData[7]
    }
  }
}

export class RFIDScannersRowData {
  label
  device_state
  status
  id
  serial_number
  name
  tcin
  updated_on
  mac_address

  constructor(rowData) {
    if (rowData !== undefined) {
      this.label = rowData[0]
      this.device_state = rowData[1]
      this.status = rowData[2]
      this.id = rowData[3]
      this.serial_number = rowData[4]
      this.name = rowData[5]
      this.tcin = rowData[6]
      this.updated_on = rowData[7]
      this.mac_address = rowData[8]
    }
  }
}

export class RegistersRowData {
  id
  serial_number
  hand_scanner_serial_number
  mac_address
  flatbed_serial_number
  updated_on
  gdp_serial_number

  constructor(rowData) {
    if (rowData !== undefined) {
      this.id = rowData[0]
      this.serial_number = rowData[1]
      this.mac_address = rowData[2]
      this.gdp_serial_number = rowData[3]
      this.hand_scanner_serial_number = rowData[4]
      this.flatbed_serial_number = rowData[5]
      this.updated_on = rowData[6]
    }
  }
}

export default columns
